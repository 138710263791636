import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'

import MenuAppBar from "./MenuAppBar"
import MonitoredDevices from './MonitoredDevices'
import SubmitField from './SubmitField'

import { logout } from './redux/auth'
import { setupSocket } from './redux/socket'
import { fetchDevices } from './redux/devices'

const container = {
  marginTop: '24px',
  marginBottom: '24px',
}

class Main extends React.Component {
  componentDidMount() {
    const cookie = this.props.cookies.get('conveyorbelt-session')
    if (cookie) {
      this.props.dispatch(setupSocket())
      this.props.dispatch(fetchDevices())
    } else {
      this.props.dispatch(logout())
    }
  }

  render() {
    return !this.props.authenticated ? (
      <Redirect to="/login" />
    ) : (
      <Fragment>
        <MenuAppBar/>
        <Grid container justify="center" style={container}>
          <Grid item container style={{ maxWidth: '960px' }}>
            <Grid item xs={12}>
              <SubmitField/>
            </Grid>
            <Grid item xs={12}>
              <MonitoredDevices/>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
})

export default withCookies(connect(
  mapStateToProps,
)(Main))
