import React from 'react'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import { grey, green, orange, red } from '@material-ui/core/colors'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'

import { forgetDevice } from './redux/devices'

const interval = 1000

class MonitoredDevices extends React.Component {
  state = {
    now: Date.now(),
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, interval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  tick = () => {
    this.setState({ now: Date.now() })
  }

  render() {
    const { items } = this.props.devices

    return (
      <Grid container spacing={8}>
        {
          items.map(item => (
            <Grid item xs={12} key={item.label}>
              <MonitoredDevice
                item={item}
                dispatch={this.props.dispatch}
                now={this.state.now}
              />
            </Grid>
          ))
        }
      </Grid>
    )
  }
}

class MonitoredDevice extends React.Component {
  state = {
    expanded: false,
  }

  toggle = () => {
    if (this.props.item.test) {
      this.setState({ expanded: !this.state.expanded })
    }
  }

  dismiss = () => {
    this.props.dispatch(forgetDevice(this.props.item.label))
  }

  render() {
    const { item, now } = this.props
    const props = renderedItemProps(item, now)

    return (
      <Card>
        <CardHeader
          style={{ alignItems: 'flex-start' }}
          avatar={
            <Avatar style={{ marginTop: '4px', backgroundColor: props.color }}>
              { props.icon }
            </Avatar>
          }
          action={
            <Button onClick={this.dismiss}>
              { item.test ? 'Dismiss' : 'Forget' }
            </Button>
          }
          title={
            <div onClick={this.toggle}>
              { item.label }
            </div>
          }
          subheader={
            <div onClick={this.toggle}>
              { this.state.expanded ? props.full : props.short }
            </div>
          }
        />
      </Card>
    )
  }
}

const renderedItemProps = (item, now) => {
  const time = formatElapsedTime(item.since, now)
  const test = item.test

  if (item.poweringOff) {
    return {
      icon: <PowerSettingsNew/>,
      short: `All tests passed, powering off... (${time})`,
      full: `Sensor tests have passed, device is powering off... (${time})`,
      color: grey[400],
    }
  }

  if (!test) {
    return {
      icon: <MoreHoriz/>,
      short: `Waiting for data... (${time})`,
      full: `Waiting for data... (${time})`,
      color: grey[400],
    }
  }

  switch (test.status) {
    case 'ok': {
      return {
        icon: <Check/>,
        short: 'All tests passed.',
        full: (
          <ul>
            {
              Object.keys(test.reading).map((key, index) => (
                <li key={index}>{`${key}: ${test.reading[key]}`}</li>
              ))
            }
          </ul>
        ),
        color: green[500],
      }
    }
    case 'warn': {
      return {
        icon: <Close/>,
        short: `${test.warnings.length} test warnings! (${time})`,
        full: (
          <div>
            {test.warnings.length} test warnings! ({time})
            <ul>
              {
                test.warnings.map((warning, index) => (
                  <li key={index}>{ warning }</li>
                ))
              }
            </ul>
          </div>
        ),
        color: orange[500],
      }
    }
    case 'timeout': {
      return {
        icon: <Close/>,
        short: `Timed out! (${time})`,
        full: (
          <div>
            Timed out! ({time})
            <ul>
              {
                test.errors.map((error, index) => (
                  <li key={index}>{ error }</li>
                ))
              }
            </ul>
          </div>
        ),
        color: red[500],
      }
    }
    case 'disconnect': {
      return {
        icon: <Close/>,
        short: `Disconnected! (${time})`,
        full: (
          <div>
            Device disconnected before poweroff! ({time})
            <ul>
              {
                test.errors.map((error, index) => (
                  <li key={index}>{ error }</li>
                ))
              }
            </ul>
          </div>
        ),
        color: red[500],
      }
    }
    case 'fail': {
      return {
        icon: <Close/>,
        short: `${test.errors.length} tests failed! (${time})`,
        full: (
          <div>
            {test.errors.length} tests failed! ({time})
            <ul>
              {
                test.errors.map((error, index) => (
                  <li key={index}>{ error }</li>
                ))
              }
            </ul>
          </div>
        ),
        color: red[500],
      }
    }
    default: {
      return {
        icon: <MoreHoriz/>,
        short: 'Wtf?',
        full: 'Wtf?',
        color: grey[400],
      }
    }
  }
}

const formatElapsedTime = (since, now) => {
  const millis = (now - since)
  const secs = Math.max(0, Math.floor(millis / 1000))
  const mins = Math.floor(secs / 60)
  return `${mins}:${((secs % 60) < 10) ? '0' : ''}${secs % 60}`
}

const mapStateToProps = (state) => ({
  devices: state.devices,
})

export default connect(
  mapStateToProps,
)(MonitoredDevices)
