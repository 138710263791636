import React from "react"
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'

import { monitorDevice } from './redux/devices'

class SubmitField extends React.Component {
  state = {
    deviceId: '',
  }

  input = null

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.input) {
      this.input.focus()
    }
  }

  changeDeviceId = (event) => {
    const deviceId = event.target.value.toUpperCase()
    this.setState(
      { deviceId },
      () => this.tryToSubmitDeviceId(),
    )
  }

  keyPress = (event) => {
    if (event.keyCode === 13) {
      this.tryToSubmitDeviceId()
    }
  }

  tryToSubmitDeviceId = () => {
    const deviceId = this.state.deviceId.trim()
    if (deviceId.length !== 8) {
      return false
    }
    this.props.dispatch(monitorDevice(deviceId))
    this.setState({ deviceId: '' })
    return true
  }

  render() {
    return (
      <FormControl fullWidth>
        <TextField
          disabled={!this.props.connected}
          label="Device ID to watch"
          value={this.props.connected ? this.state.deviceId : "ERROR: Not connected!"}
          onChange={this.changeDeviceId}
          onKeyUp={this.keyPress}
          onBlur={(event) => {
            const target = event.target
            setTimeout(() => target.focus(), 100)
          }}
          autoFocus
          margin="normal"
          variant="outlined"
          inputRef={input => this.input = input}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!this.props.connected}
                  onClick={this.tryToSubmitDeviceId}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    )
  }
}

const mapStateToProps = (state) => ({
  connected: state.socket.socket !== null,
})

export default connect(
  mapStateToProps,
)(SubmitField)
