import axios from 'axios'

import store from './store'
import { logout } from './auth'

const api = axios.create({
  withCredentials: true,
})

// Log out if the server gives us shit
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      console.log('Got a 401, logging out.')
      store.dispatch(logout())
    }
    return Promise.reject(err)
  }
)

const login = async (username, password) => {
  const data = {
    username,
    password,
  }
  await api.post('/api/login', data)
}

const getAllDevices = async () => {
  const res = await api.get('/api/devices')
  return res.data
}

const monitorDevice = async (label) => {
  const res = await api.post(`/api/devices/${label}/monitor`)
  return res.data
}

const forgetDevice = async (label) => {
  await api.post(`/api/devices/${label}/forget`)
}

const restartReportsClient = async () => {
  await api.post(`/api/reports/restart`)
}

export default {
  app: {
    login,
  },
  devices: {
    getAll: getAllDevices,
    monitor: monitorDevice,
    forget: forgetDevice,
  },
  reports: {
    restart: restartReportsClient,
  },
}
