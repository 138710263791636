import axios from 'axios'

import { authenticated } from './auth'

const INITIALIZING = 'app/INITIALIZING'
const INITIALIZED = 'app/INITIALIZED'

export const initialize = () => (dispatch) => {
  const cookie = findCookie()
  if (cookie === null) {
    dispatch(initialized())
    return
  }

  const probe = axios.create()
  dispatch(initializing())
  probe.get('/api/devices')
    .then(() => {
      dispatch(authenticated())
      dispatch(initialized())
    })
    .catch(() => {
      dispatch(initialized())
    })
}

const findCookie = () => {
  const cookies = document.cookie
    .split(';')
    .map(cookie => cookie.trim().split('='))
    .filter(pair => pair[0] === 'conveyorbelt-session')
    .map(pair => pair[1])

  return cookies.length > 0 ? cookies[0] : null
}

const initializing = () => ({
  type: INITIALIZING,
})

const initialized = () => ({
  type: INITIALIZED,
})

export default function reducer(
  state = {
    initializing: false,
    initialized: false,
  },
  action = {}
) {
  switch (action.type) {
    case INITIALIZING: {
      return {
        ...state,
        initializing: true,
        initialized: false,
      }
    }
    case INITIALIZED: {
      return {
        ...state,
        initializing: false,
        initialized: true,
      }
    }
    default: {
      return state
    }
  }
}
