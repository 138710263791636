import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import PrivateRoute from './PrivateRoute'
import Login from './Login'
import Main from './Main'
import { initialize } from './redux/app'

class App extends React.Component {
  componentDidMount() {
    if (this.props.initialized) {
      return
    }
    if (this.props.initializing) {
      return
    }
    this.props.dispatch(initialize())
  }

  render() {
    return this.props.initialized ? (
      <Router>
        <div>
          <Route exact path="/login" component={() => <Login />} />
          <PrivateRoute exact path="/" component={() => <Main />} />
        </div>
      </Router>
    ) : (
      null
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.app,
})

export default withCookies(connect(
  mapStateToProps,
)(App))
