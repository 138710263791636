import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import app from './app'
import auth from './auth'
import devices from './devices'
import socket from './socket'

const reducer = combineReducers({
  app,
  auth,
  devices,
  socket,
})

const store = createStore(
  reducer,
  applyMiddleware(
    thunk,
    logger,
  ),
)

export default store
