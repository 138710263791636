import api from './api'

const FETCH_DEVICES_REQUEST = 'devices/FETCH_REQUEST'
const FETCH_DEVICES_SUCCESS = 'devices/FETCH_SUCCESS'
const FETCH_DEVICES_FAILURE = 'devices/FETCH_FAILURE'

const MONITOR_DEVICE_REQUEST = 'devices/MONITOR_REQUEST'
const MONITOR_DEVICE_SUCCESS = 'devices/MONITOR_SUCCESS'
const MONITOR_DEVICE_FAILURE = 'devices/MONITOR_FAILURE'

const FORGET_DEVICE_REQUEST = 'devices/FORGET_REQUEST'
const FORGET_DEVICE_SUCCESS = 'devices/FORGET_SUCCESS'
const FORGET_DEVICE_FAILURE = 'devices/FORGET_FAILURE'

const DEVICE_POWERING_OFF = 'devices/POWERING_OFF'
const DEVICE_TEST_RESULTS = 'devices/TEST_RESULTS'

export const fetchDevices = () => (dispatch, getState) => {
  const { fetching } = getState().devices

  if (fetching) {
    return
  }

  dispatch(fetchDevicesRequest())
  api.devices.getAll()
    .then(res => dispatch(fetchDevicesSuccess(res)))
    .catch(err => dispatch(fetchDevicesFailure(err)))
}

const fetchDevicesRequest = () => ({
  type: FETCH_DEVICES_REQUEST,
})

const fetchDevicesSuccess = (res) => ({
  type: FETCH_DEVICES_SUCCESS,
  items: res.devices,
})

const fetchDevicesFailure = (error) => ({
  type: FETCH_DEVICES_FAILURE,
  error,
})

export const monitorDevice = (label) => (dispatch, getState) => {
  const { items } = getState().devices
  const item = items.find(item => item.label === label)

  if (item) {
    dispatch(monitorDeviceFailure(label, 'Device is already being monitored'))
    return
  }

  dispatch(monitorDeviceRequest(label))
  api.devices.monitor(label)
    .catch(err => dispatch(monitorDeviceFailure(label, err)))
}

const monitorDeviceRequest = (label) => ({
  type: MONITOR_DEVICE_REQUEST,
  label,
})

const monitorDeviceFailure = (label, error) => ({
  type: MONITOR_DEVICE_FAILURE,
  label,
  error,
})

export const forgetDevice = (label) => (dispatch, getState) => {
  const { items } = getState().devices
  const item = items.find(item => item.label === label)

  if (!item) {
    dispatch(forgetDeviceFailure(label, 'Device is not being monitored'))
    return
  }

  dispatch(forgetDeviceRequest(label))
  api.devices.forget(label)
    .catch(err => {
      if (err.response.status !== 401) {
        dispatch(forgetDeviceFailure(label, err))
      }
    })
}

const forgetDeviceRequest = (label) => ({
  type: FORGET_DEVICE_REQUEST,
  label,
})

const forgetDeviceFailure = (label, error) => ({
  type: FORGET_DEVICE_FAILURE,
  label,
  error,
})

export default function reducer(
  state = {
    items: [],
    valid: false,
    fetching: false,
  },
  action = {},
) {
  switch (action.type) {
    case FETCH_DEVICES_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        items: action.items,
        valid: true,
        fetching: false,
      }
    }
    case FETCH_DEVICES_FAILURE: {
      if (action.error.response.status !== 401) {
        alert(action.error)
      }
      return {
        ...state,
        fetching: false,
      }
    }
    case MONITOR_DEVICE_SUCCESS: {
      return {
        ...state,
        items: [
          ...state.items,
          deviceReducer(null, action)
        ],
      }
    }
    case MONITOR_DEVICE_FAILURE: {
      if (isResponse(action) && !is401(action)) {
        if (action.error.response.status === 404) {
          alert(`Device ${action.label} was not found.`)
        } else {
          alert(action.error)
        }
      }
      return state
    }
    case FORGET_DEVICE_SUCCESS: {
      const index = state.items.findIndex(item => item.label === action.label)
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          ...state.items.slice(index + 1),
        ],
      }
    }
    case FORGET_DEVICE_FAILURE: {
      if (isResponse(action) && !is401(action)) {
        if (action.error.response.status === 404) {
          console.warn(`Tried to forget ${action.label}, but it wasn't being monitored.`)
          const index = state.items.findIndex(item => item.label === action.label)
          return {
            ...state,
            items: [
              ...state.items.slice(0, index),
              ...state.items.slice(index + 1),
            ],
          }
        } else {
          alert(action.error)
        }
      }
      return state
    }
    case DEVICE_POWERING_OFF: {
      const index = state.items.findIndex(item => item.label === action.label)
      if (index === -1) {
        return state
      }
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          deviceReducer(state.items[index], action),
          ...state.items.slice(index + 1),
        ],
      }
    }
    case DEVICE_TEST_RESULTS: {
      const index = state.items.findIndex(item => item.label === action.label)
      if (index === -1) {
        const device = { label: action.label, imei: '', result: null }
        return {
          ...state,
          items: [
            ...state.items.slice(),
            deviceReducer(device, action),
          ],
        }
      }
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          deviceReducer(state.items[index], action),
          ...state.items.slice(index + 1),
        ],
      }
    }
    default: {
      return state
    }
  }
}

const isResponse = (action) =>
  action.error &&
  action.error.response

const is401 = (action) =>
  action.error &&
  action.error.response &&
  action.error.response.status === 401

const deviceReducer = (
  state = {
    label: '',
    imei: '',
    since: null,
    poweringOff: false,
    test: null,
  },
  action = {},
) => {
  switch (action.type) {
    case MONITOR_DEVICE_SUCCESS: {
      return {
        ...state,
        label: action.label,
        imei: action.imei,
        since: action.since,
        test: action.test,
      }
    }
    case DEVICE_POWERING_OFF: {
      return {
        ...state,
        poweringOff: true,
      }
    }
    case DEVICE_TEST_RESULTS: {
      return {
        ...state,
        poweringOff: false,
        test: action.test,
      }
    }
    default: {
      return state
    }
  }
}
