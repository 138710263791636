import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import api from './redux/api'

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleContextMenuClick(event) {
    api.reports.restart()
      .then(() => window.alert('MQTT client restarted.'))
      .catch(window.alert)

    setAnchorEl(null)
  }

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', boxShadow: 'none' }}>
      <Toolbar>
        <IconButton
          onClick={handleMenu}
          color="primary"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleContextMenuClick}>Restart MQTT client</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
