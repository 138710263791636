import api from './api'

const AUTHENTICATE_REQUEST = 'auth/AUTHENTICATE_REQUEST'
const AUTHENTICATE_SUCCESS = 'auth/AUTHENTICATE_SUCCESS'
const AUTHENTICATE_FAILURE = 'auth/AUTHENTICATE_FAILURE'

const LOGOUT = 'auth/LOGOUT'

export const login = (password) => (dispatch, getState) => {
  const { authenticating } = getState().auth
  if (authenticating) {
    return Promise.resolve()
  }

  dispatch(authenticateRequest())
  return api.app.login('sordo', password)
    .then(() => dispatch(authenticateSuccess()))
    .catch(err => dispatch(authenticateFailure(err)))
}

export const authenticated = () => ({
  type: AUTHENTICATE_SUCCESS,
})

export const logout = () => ({
  type: LOGOUT,
})

const authenticateRequest = () => ({
  type: AUTHENTICATE_REQUEST,
})

const authenticateSuccess = () => ({
  type: AUTHENTICATE_SUCCESS,
})

const authenticateFailure = (error) => ({
  type: AUTHENTICATE_FAILURE,
  error,
})

export default function reducer(
  state = {
    authenticating: false,
    authenticated: false,
  },
  action = {},
) {
  switch (action.type) {
    case AUTHENTICATE_REQUEST: {
      return {
        ...state,
        authenticating: true,
      }
    }
    case AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        authenticating: false,
        authenticated: true,
      }
    }
    case AUTHENTICATE_FAILURE: {
      if (action.error.response.status !== 401) {
        alert(action.error)
      }
      return {
        ...state,
        authenticating: false,
        authenticated: false,
      }
    }
    case LOGOUT: {
      return {
        ...state,
        authenticated: false,
      }
    }
    default: {
      return state
    }
  }
}
