import React from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import { login } from './redux/auth'

const container = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const content = {
  width: '400px',
  flex: 'none',
  color: 'grey',
  fontFamily: 'Times, sans-serif',
  fontSize: '24pt',
  fontStyle: 'italic',
  textAlign: 'center',
}

const styles = () => ({
  input: {
    width: '180px',
    height: '24pt',
    marginLeft: '8px',
    color: 'grey',
    fontFamily: 'Times, sans-serif',
    fontSize: '20pt',
    fontStyle: 'italic',
  },
})

class Login extends React.Component {
  state = {
    password: '',
  }

  changePassword = (event) => {
    const password = event.target.value
    this.setState({ password })
  }

  submit = (event) => {
    event.preventDefault()

    const password = this.state.password
    this.setState(
      { password: '' },
      () => this.props.dispatch(login(password))
    )
  }

  render() {
    if (this.props.authenticated) {
      return <Redirect to="/" />
    }

    const { classes } = this.props
    const input = {
      classes: {
        root: classes.input,
      }
    }

    const password = (
      <TextField
        type="password"
        name="password"
        value={this.state.password}
        onChange={this.changePassword}
        InputProps={input}
        autoFocus
      />
    )
    return (
      <div style={container}>
        <div style={content}>
          <form method="POST" action="/login" onSubmit={this.submit}>
            Crack of dawn, all is gone<br/>
            except {password}
            <input type="hidden" name="username" value={process.env.REACT_APP_AUTH_USERNAME} />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
})

export default withCookies(connect(
  mapStateToProps,
)(withStyles(styles)(Login)))
