import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const PrivateRoute = (props) => {
  const authenticated = props.authenticated
  const Component = props.component
  return (
    authenticated
      ? <Component />
      : <Redirect to="/login" />
  )
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
})

export default connect(
  mapStateToProps,
)(PrivateRoute)
