import { logout } from './auth'

const SOCKET_CONNECTING = 'socket/CONNECTING'
const SOCKET_CONNECTED = 'socket/CONNECTED'
const SOCKET_DISCONNECTED = 'socket/DISCONNECTED'

export const setupSocket = () => (dispatch, getState) => {
  const state = getState().socket
  if (state.socket && state.socket.isConnected) {
    return
  }

  dispatch(socketConnecting())

  const protocol = window.location.protocol.replace('http', 'ws')
  const host = window.location.host

  const socket = new WebSocket(`${protocol}//${host}/api/socket`)
  socket.onopen = () => {
    console.log('Socket connected.')
    dispatch(socketConnected(socket))
    heartbeat(socket)
  }
  socket.onclose = () => {
    console.log('Socket disconnected. Logging out...')
    dispatch(socketDisconnected())
    dispatch(logout())
  }
  socket.onmessage = (event) => {
    if (event.data === 'pong') {
      return heartbeat(socket)
    }

    const data = parseData(event)
    if (data.type === undefined) {
      console.warn('Weird event D:', event)
    } else {
      const action = {
        type: data.type,
        ...data.payload,
      }
      dispatch(action)
    }
  }
}

const heartbeat = (socket) => {
  setTimeout(() => {
    if (socket.readyState !== socket.OPEN) {
      return
    }
    socket.send('ping')
  })
}

const socketConnecting = () => ({
  type: SOCKET_CONNECTING,
})

const socketConnected = (socket) => ({
  type: SOCKET_CONNECTED,
  socket,
})

const socketDisconnected = () => ({
  type: SOCKET_DISCONNECTED,
})

export default function reducer(
  state = {
    socket: null,
    connecting: false,
  },
  action = {},
) {
  switch (action.type) {
    case SOCKET_CONNECTING: {
      return {
        ...state,
        socket: action.socket,
        connecting: true,
      }
    }
    case SOCKET_CONNECTED: {
      return {
        ...state,
        connecting: false,
      }
    }
    case SOCKET_DISCONNECTED: {
      return {
        ...state,
        socket: null,
        connecting: false,
      }
    }
    default: {
      return state
    }
  }
}

const parseData = (event) => {
  try {
    return JSON.parse(event.data)
  } catch (e) {
    console.error('Failed to parse message from socket!', e)
    return {}
  }
}
